export const ROLE_AUTHOR = 'author';
export const ROLE_EDITOR = 'editor';
export const ROLE_REVIEWER = 'reviewer';
export const ROLE_ADMIN = 'admin';

export const conference = {
    abstractStatus: {
        SUBMITTED: 'Submitted',
        RESUBMITTED: 'Resubmitted',
        UNDER_REVIEW: 'Under review',
        ACCEPTED: 'Accepted',
        CONDITIONALLY_ACCEPTED: 'Conditionally accepted',
        REVISE_REQUIRED: 'Needs to be revised',
        REJECTED: 'Rejected'
    },
    abstractStatusTip: {
        SUBMITTED: 'Your submission is waiting for reviewer assignment.',
        RESUBMITTED: 'Abstract has been resubmitted and waiting for reviewer or editor action',
        UNDER_REVIEW: 'Submission is under review.',
        ACCEPTED: 'Your submission has been accepted.',
        CONDITIONALLY_ACCEPTED: 'Some minor changes in your submission are required.',
        REVISE_REQUIRED: 'Major changes in your submission are required.',
        REJECTED: 'We are sorry, but your submission has not been accepted.'
    },
    abstractStatusColor: {
        SUBMITTED: 'blue',
        RESUBMITTED: 'geekblue',
        UNDER_REVIEW: 'purple',
        ACCEPTED: 'green',
        CONDITIONALLY_ACCEPTED: 'gold',
        REVISE_REQUIRED: 'orange',
        REJECTED: 'red'
    },
    reviewDecision: [ 'ACCEPTED', 'CONDITIONALLY_ACCEPTED', 'REVISE_REQUIRED', 'REJECTED'],
    authorCanResponse: ['CONDITIONALLY_ACCEPTED', 'REVISE_REQUIRED'],
    authorCanEdit: ['SUBMITTED'],
    authorCanDelete: ['SUBMITTED'],
    events: {
        workshopMath: 'Workshop: Mathematica in computer simulations',
        workshopOptim: 'Workshop: Generative design technology in Fusion 360',
        cityGame: 'Social event: Krakow City game',
        quiz: 'Social event: The Pub Quiz with Fizz',
        tour: 'Social event: Cracovia walking tour',
        accommodation: 'Accommodation'
    }
}
