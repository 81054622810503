export default {
    sort: {
        xxl: 2,
        xl: 2,
        lg: 3,
        md: 4,
        sm: 6
    },
    firstName: {
        xxl: 4,
        xl: 4,
        lg: 6,
        md: 10,
        sm: 9
    },
    lastName: {
        xxl: 4,
        xl: 4,
        lg: 6,
        md: 10,
        sm: 9
    },
    email: {
        xxl: {span: 4, offset: 0},
        xl: {span: 4, offset: 0},
        lg: {span: 9, offset: 0},
        md: {span: 10, offset: 4},
        sm: {span: 9, offset: 6}
    },
    affiliation: {
        xxl: {span: 7, offset: 0},
        xl: {span: 7, offset: 0},
        lg: {span: 17, offset: 3},
        md: 10,
        sm: 9
    },
    speaker: {
        xxl: {span: 1, offset: 0},
        xl: {span: 1, offset: 0},
        lg: {span: 2, offset: 0},
        md: {span: 2, offset: 4},
        sm: {span: 2, offset: 6}
    },
    remove: {
        xxl: 1,
        xl: 1,
        lg: 1,
        sm: 3
    }
}
