import React from 'react'
import {Button} from "antd";

export default {
    name: 'mf2020',
    title: 'MetalForming 2020',
    subTitle: '18th International Conference on Metal Forming',
    conferenceSiteUrl: 'http://metalforming.agh.edu.pl/',
    registrationFormText: (<span>Complete the form below in order to register for the Metal Forming Conference,
        September 13-16, 2020, Krakow, Poland. If you experience any problems with the site, you may wish to try another browser.
        If you continue to have problems, please contact us at <a
            href="mailto:metalforming@agh.edu.pl">metalforming@agh.edu.pl</a></span>),
    feeTabVisible: true,
    backgroundImage: '/background_mf2020.webp',
    abstractTemplate: '/MF2020_Abstract_Template.docx',
    paperTemplate: 'http://metalforming.agh.edu.pl/wp-content/uploads/2020/01/PROMFG_MetalForming_2020_Templates.zip',
    copyrightsForm: null,
    sentryDsn: 'https://44a76100c90d40ad958f5a036c61d58b@sentry.kisim.eu.org/3',
    feeText: (<div style={{textAlign: 'center'}}>

                <p>
                    <strong>Payments are processed in cooperation with our partner – Jordan Group.</strong><br/>
                </p>
                <p>
                    <img src="/jordan.jpg" alt="Jordan Group logo" width={300}/>
                </p>
                <p>
                    Details and payment methods are available on the <a href="http://metalforming.agh.edu.pl/fee/"
                                                                        target="_blank" rel="noopener noreferrer">Conference
                    Website</a>
                </p>
                <p>
                    <Button size="large" type="primary"
                            onClick={() => window.open("https://metalforming2020.jordan.pl/en/create-account")}>Pay
                        now</Button>
                </p>

                <p>
                    If your fee's status does not change within a few days of making the payment,
                    please
                    contact the conference secretariat: <a href="mailto:barana@agh.edu.pl">Anna
                    Smyk (barana@agh.edu.pl)</a>
                </p>
        </div>
    ),
    menu: {
        papersList: 'List of full papers',
        submitPaper: 'Submit full paper'
    },
    fees: [
        {
            id: 'FULL_EARLY',
            label: 'Full early'
        },
        {
            id: 'FULL_LATE',
            label: 'Full late'
        },
        {
            id: 'STUDENT_EARLY',
            label: 'Student early'
        },
        {
            id: 'STUDENT_LATE',
            label: 'Student late'
        },
        {
            id: 'STAFF',
            label: 'Technical staff'
        },
        {
            id: 'INVITED',
            label: 'Invited'
        },
        {
            id: 'CASH',
            label: 'Cash on desk'
        }
    ],
    apiUrl: process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:9000/api',
}
