import React from "react";
import {Route, Switch} from "react-router-dom";
import NotFound from "../pages/NotFound";
import {preloadComponentsFor, routing} from "./routing-data";
import {AuthRoute} from "./AuthRoute";
import {useSelector} from "react-redux";

export const Routing = () => {
    const auth = useSelector(state => state.auth);
    preloadComponentsFor(auth.user.role)

    return (
        <Switch>
            {
                routing.map((route) =>
                    <AuthRoute
                        path={route.path}
                        component={route.component}
                        key={route.path}
                        exact={true}
                    />
                )
            }
            <Route component={NotFound}/>
        </Switch>
    );
}
