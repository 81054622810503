import {
    API_REVIEWER_ADD_PAPER_REVIEW,
    API_REVIEWER_GET_ABSTRACT,
    API_REVIEWER_GET_ALL_ABSTRACTS_REVIEWS,
    API_REVIEWER_GET_ALL_PAPERS_REVIEWS,
    API_REVIEWER_GET_PAPER
} from "../constants/action-types";

const initialState = {
    abstractsReviews: [],
    papersReviews: [],
    paper: {},
    abstract: {}
};

const reviewerReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${API_REVIEWER_GET_ALL_ABSTRACTS_REVIEWS}_SUCCESS`:
            return {
                ...state, abstractsReviews: action.payload.data.map((r, index) => { return {...r, no: index + 1}})
            }
        case `${API_REVIEWER_GET_ALL_PAPERS_REVIEWS}_SUCCESS`:
            return {
                ...state, papersReviews: action.payload.data.map((r, index) => { return {...r, no: index + 1}})
            };
        case `${API_REVIEWER_ADD_PAPER_REVIEW}_SUCCESS`:
        case `${API_REVIEWER_GET_PAPER}_SUCCESS`:
            return {
                ...state, paper: action.payload.data
            };
        case `${API_REVIEWER_GET_ABSTRACT}_SUCCESS`:
            return {
                ...state, abstract: action.payload.data
            };

        default:
            return state

    }
}

export default reviewerReducer;
