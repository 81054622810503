import {
    API_AUTHOR_DELETE_ABSTRACT, API_AUTHOR_DELETE_PAPER,
    API_AUTHOR_GET_ABSTRACT,
    API_AUTHOR_GET_ABSTRACTS,
    API_AUTHOR_GET_KEYWORDS, API_AUTHOR_GET_PAPER, API_AUTHOR_GET_PAPERS,
    API_AUTHOR_SUBMIT_ABSTRACT, API_AUTHOR_SUBMIT_PAPER,
    AUTHOR_ADD_FIRST_AUTHOR_TO_ABSTRACT, AUTHOR_ADD_FIRST_AUTHOR_TO_PAPER,
    AUTHOR_CLEAR_STORED_ABSTRACT, AUTHOR_CLEAR_STORED_PAPER
} from "../constants/action-types";

const initialState = {
    abstracts: [],
    abstract: {},
    papers: [],
    paper: {},
    keywords: []
};
const authorReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${API_AUTHOR_SUBMIT_ABSTRACT}_SUCCESS`:
            return { ...state };
        case `${API_AUTHOR_GET_ABSTRACTS}_SUCCESS`:
            return {...state, abstracts: action.payload.data}
        case `${API_AUTHOR_GET_ABSTRACT}_SUCCESS`:
            return {...state, abstract: action.payload.data}
        case `${API_AUTHOR_DELETE_ABSTRACT}_SUCCESS`:
            return {...state}
        case AUTHOR_CLEAR_STORED_ABSTRACT:
            return {...state, abstract: {}}
        case AUTHOR_ADD_FIRST_AUTHOR_TO_ABSTRACT:
            let authors = [action.user]
            return {...state, abstract: {authors}}
        case `${API_AUTHOR_SUBMIT_PAPER}_SUCCESS`:
            return { ...state };
        case `${API_AUTHOR_GET_PAPERS}_SUCCESS`:
            return {...state, papers: action.payload.data};
        case `${API_AUTHOR_GET_PAPER}_SUCCESS`:
            return {...state, paper: action.payload.data};
        case `${API_AUTHOR_DELETE_PAPER}_SUCCESS`:
            return {...state};
        case AUTHOR_CLEAR_STORED_PAPER:
            return {...state, paper: {}};
        case AUTHOR_ADD_FIRST_AUTHOR_TO_PAPER:
            return {...state, paper: {authors: [action.user]}};
        case `${API_AUTHOR_GET_KEYWORDS}_SUCCESS`:
            return {...state, keywords: action.payload.data}
        default:
            return state;
    }
}

export default authorReducer;
