import React, {useState} from 'react';
import {Button, Form, Input, message} from 'antd';
import styles from './Login.module.scss'
import {resetPassword} from "../redux-actions/auth";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {compareToFirstPassword} from "../validators/comparePasswords";

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        xs: {span: 10},
        sm: {span: 10},
    },
    wrapperCol: {
        xs: {span: 14},
        sm: {span: 14},
    },
};

const ResetPassword = () => {
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [form] = Form.useForm()
    const history = useHistory();
    const {token} = useParams();
    const dispatch = useDispatch();

    const handleSubmit = (values) => {
        setBtnDisabled(true)
        const password = values.password
        dispatch(resetPassword(token, password)).then(() => {
            message.loading('Password has been change. You can proceed to the login page...', 1.0)
                .then(() => history.push('/'))
        })
            .catch((response) => {
                message.error('Could not change a password.');
            })
    }


    return (
        <Form form={form} onFinish={handleSubmit}>
            <h2>Reset your password</h2>
            <span>Please enter your new password below to reset</span>
            <FormItem
                {...formItemLayout}
                label="Password"
                name='password'
                validateTrigger="onBlur"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                    {
                        min: 6, message: 'Password should have at least 6 characters'
                    }]}
            >
                <Input.Password data-cy="password1"/>
            </FormItem>
            <FormItem
                {...formItemLayout}
                label="Confirm Password"
                name='confirm'
                rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    compareToFirstPassword
                ]}>
                <Input.Password data-cy="password2" />

            </FormItem>
            <FormItem>
                <Button disabled={btnDisabled} type="primary" htmlType="submit"
                        className={styles.loginFormButton}>
                    Change password
                </Button>
            </FormItem>
        </Form>
    );

}

export default ResetPassword;
