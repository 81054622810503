import React, {useEffect} from 'react'
import If from "babel-plugin-jsx-control-statements";
import {Modal} from "antd";

const MissingEntries = ({isEmail, isAffiliation}) => {
    return (
        <div>
                <p>
                    <strong>Thank you for registering with ORCID</strong>
                </p>
                <If condition={!isEmail || !isAffiliation}>
                    <span style={{marginTop: 10}}>
                        We respect your privacy, so we only retrieved public data from your ORCID profile.
                        Some details were not publicly available, therefore to complete your registration we need to ask for:
                    </span>

                    <ul style={{marginTop: 10}}>
                        <If condition={!isEmail}>
                            <li>Email address</li>
                        </If>
                        <If condition={!isAffiliation}>
                            <li>Affiliation</li>
                        </If>
                    </ul>
                    <span>
                        Please complete your profile in our system before submitting your work.
                    </span>
                </If>
        </div>
    )
}

export const OrcidRegistration = ({user}) => {
    const {id, email, affiliation} = user;

    useEffect(() => {
        if(id) {
            const profileValidation = {
                isEmail: !!email,
                isAffiliation: !!affiliation,
            }
            Modal.success({
                content: <MissingEntries {...profileValidation}/>,
            });
        }
    }, [id, email, affiliation])

    return null

}
