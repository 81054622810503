import React, {Component} from 'react';
import {Tag} from 'antd';
import {Link, withRouter} from "react-router-dom";
import styles from "./Abstracts.module.scss";
import PropTypes from "prop-types"
import {conference} from "../constants/strings";
import {StyledTable} from "./Components/StyledTable";
import {NoPaddingCard} from "./Components/NoPaddingCard";

class Papers extends Component {

    columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (title, record) => (
                <span><Link to={`${this.props.urlPrefix}/${record.id}`}>{title}</Link></span>
            )
        },
        {
            title: 'Authors',
            dataIndex: 'authors',
            render: (authors, record) => (<ul className={styles.authorList}>{authors.map((author, authorIndex) => <li
                key={author.id}
                style={{textDecoration: authorIndex === record.presenter ? 'underline' : 'none'}}>{author.lastName} {author.firstName}</li>)}</ul>),
            key: 'author',
            width: 250,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => (
                <span>
                    <Tag color={conference.abstractStatusColor[status]}>{conference.abstractStatus[status]}</Tag>
                </span>
            ),
            width: 150,
        }, {
            title: 'Submission date',
            key: 'submissionDate',
            dataIndex: 'createdAt',
            render: date => <span>{date.substring(0, 10)}</span>,
            width: 150,
        }
    ];

    render() {
        return (
            <div>
                <NoPaddingCard title="Submitted papers">
                    <StyledTable
                        rowKey="id"
                        columns={this.columns}
                        dataSource={this.props.papers}
                        pagination={false}
                    />
                </NoPaddingCard>
            </div>
        );
    }
}

Papers.propTypes = {
    papers: PropTypes.array,
};
Papers = withRouter(Papers)

export default Papers;
