import React from 'react'
import {conference} from "../constants/strings";
import {Tag, Tooltip} from "antd";
import style from './StatusBadge.module.scss'

export const StatusBadge = ({status, withTooltip = false}) => {
    if(!status) return null;

    const statusColor = conference.abstractStatusColor[status] || "rgba(0, 0, 0, 0.5)";

    const renderedTag = <Tag className={style.badge} color={statusColor}>
        {conference.abstractStatus[status]}
    </Tag>

    if(withTooltip){
        return (
            <Tooltip placement="top" title={conference.abstractStatusTip[status]}>
                {renderedTag}
            </Tooltip>
        )
    }

    return renderedTag


}
