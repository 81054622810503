import {
    API_GEO_GET_COUNTRY,
    API_GET_USERINFO,
    API_LOGIN_USER,
    API_REGISTER_USER,
    API_REQUEST_NEW_PASSWORD,
    API_RESET_PASSWORD,
    API_UPDATE_USER,
    API_UPDATE_USER_ADDRESS,
    API_UPDATE_USER_EVENTS, API_UPDATE_USER_SOCIAL,
    API_UPDATE_USER_SURVEY,
    EDIT_USER_FORM,
    LOGOUT,
    RETRIEVE_AUTH_FROM_STORAGE, TOKEN_LOGIN
} from "../constants/action-types";

export const retrieveAuthFromStorage = () => {
    return {
        type: RETRIEVE_AUTH_FROM_STORAGE
    }
}

export const logout = () => {
    return {
        type: LOGOUT
    }
}

export const userFormEdit = (changedFields) => {
    return {
        type: EDIT_USER_FORM,
        changedFields
    }
}

export const userRegister = (userdata) => {
    return {
        type: API_REGISTER_USER,
        payload: {
            request: {
                url: `/users/`,
                method: 'post',
                data: userdata
            }
        },
    }
}

export const requestNewPassword = (email) => {
    return {
        type: API_REQUEST_NEW_PASSWORD,
        payload: {
            request: {
                url: `/password-resets/`,
                method: 'post',
                data: {email}
            }
        },
    }
}

export const resetPassword = (token, password) => {
    return {
        type: API_RESET_PASSWORD,
        payload: {
            request: {
                url: `/password-resets/${token}`,
                method: 'put',
                data: {password}
            }
        },
    }
}


export const userLogin = (credentials) => {
    return{
        type: API_LOGIN_USER,
        payload: {
            request: {
                url: '/auth',
                method: 'post',
                auth: {
                    username: credentials.username.trim(),
                    password: credentials.password.trim()
                }
            }
        },
    }
}

export const tokenLogin = (token) => {
    return {
        type: TOKEN_LOGIN,
        payload: {
            token
        }
    }
}

export const getCurrentUser = () => {
    return {
        type: API_GET_USERINFO,
        payload: {
            request: {
                url: `/users/me`,
                method: 'get',
            }
        },
    }
}

export const userUpdate = (data, userId) => {
    const id = userId ? userId : 'me';
    return {
        type: API_UPDATE_USER,
        payload: {
            request: {
                url: `/users/${id}`,
                method: 'put',
                data: data
            }
        },
    }
}

export const userSurveyUpdate = (data, userId) => {
    const id = userId ? userId : 'me';
    return {
        type: API_UPDATE_USER_SURVEY,
        payload: {
            request: {
                url: `/users/${id}/survey`,
                method: 'put',
                data: data
            }
        },
    }
}

export const userAddressUpdate = (data, userId) => {
    const id = userId ? userId : 'me';
    return {
        type: API_UPDATE_USER_ADDRESS,
        payload: {
            request: {
                url: `/users/${id}/invoice`,
                method: 'put',
                data: data
            }
        },
    }
}

export const userEventsUpdate = (data, userId) => {
    const id = userId ? userId : 'me';
    return {
        type: API_UPDATE_USER_EVENTS,
        payload: {
            request: {
                url: `/users/${id}/events`,
                method: 'put',
                data: data
            }
        },
    }
}

export const userSocialUpdate = (data, userId) => {
    const id = userId ? userId : 'me';
    return {
        type: API_UPDATE_USER_SOCIAL,
        payload: {
            request: {
                url: `/users/${id}/social`,
                method: 'put',
                data: data
            }
        },
    }
}

export const suggestCountry = () => {
    return {
        type : API_GEO_GET_COUNTRY,
        payload: {
            request: {
                url: `/geo/country`,
                method: 'get'
            }
        },
    }
}
