import React, {useEffect, useState} from 'react';
import {Button, Col, Form, notification, Row, Steps} from "antd";
import CredentialsForm from "./CredentialsForm";
import AddressForm from "./AddressForm";
import SurveyForm from "./SurveyForm";
import _ from 'lodash-es'
import If from "babel-plugin-jsx-control-statements"
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";

const createForms = (size) => {
    return new Array(size).fill((() => {
        const [form] = Form.useForm();
        return form
    })())
}

const Registration = ({userRegister, country}) => {
    const history = useHistory();

    const [current, setCurrent] = useState(0);
    const [formContent, setFormContent] = useState({})
    const [navigationDisabled, setNavigationDisabled] = useState(false)
    const [forms] = useState(createForms(3))

    useEffect(() => {
        forms[1].setFieldsValue({country})
    }, [forms, country])


    const wizardSteps = () => {
        return [{
            title: 'Author information',
            content: <CredentialsForm mode='register' form={forms[0]}/>,
        }, {
            title: 'Mailing address',
            content: <AddressForm form={forms[1]}/>,
        }, {
            title: 'Survey',
            content: <SurveyForm form={forms[2]}/>,
        }];
    };

    const handleNext = () => {
        forms[current].validateFields()
            .then((values) => {
                setCurrent(current + 1)
                setFormContent({...formContent, ...values})
            })
            .catch(errInfo => {})
    };


    const prev = () => {
        setCurrent(current - 1)
    };


    const handleRegister = () => {
        forms[2].validateFields()
            .then((values) => {
                setNavigationDisabled(true)
                const reqContent = {...formContent, ...values};

                userRegister(reqContent).then((response) => {
                    notification['success']({
                        message: 'Success',
                        description: 'Registration completed. Now you can log in.',
                    });
                    _.delay(() => history.push('/'), 1500)
                }).catch((failed) => {
                    let msg = _.upperFirst(_.get(failed, 'error.response.data.message', ''));
                    notification['error']({
                        message: 'Error',
                        description: `Cannot register. ${msg}`,
                    });

                    setNavigationDisabled(false)
                })
            })
    };


    const steps = wizardSteps();
    return (
        <div>
            <Steps current={current}>
                {steps.map(item => <Steps.Step key={item.title} title={item.title}/>)}
            </Steps>
            <div>{steps[current].content}</div>
            <div>
                <Row>
                    <Col offset={2} xs={10}>
                        <If condition={current > 0}>
                            <Button onClick={() => prev()}>
                                Previous
                            </Button>
                        </If>
                    </Col>

                    <Col xs={10}>

                        <If condition={current < steps.length - 1}>
                            <Button style={{float: 'right'}} type="primary" data-cy="next"
                                    onClick={handleNext}>Next</Button>
                        </If>
                        <If condition={current === steps.length - 1}>
                            <Button style={{float: 'right'}} onClick={handleRegister} data-cy="register"
                                    disabled={navigationDisabled} type="primary">Register</Button>
                        </If>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

Registration.propTypes = {
    onFormEdit: PropTypes.func.isRequired,
    formContent: PropTypes.object,
    user: PropTypes.object,
    country: PropTypes.string,
};

export default Registration
