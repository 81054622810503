import React, {useEffect, useState} from 'react';
import {Button, Col, Form, message, Modal, Row} from "antd";
import _ from 'lodash-es'
import PropTypes from "prop-types";
import Details from "./Details";
import Invoice from "./Invoice";
import Survey from "./Survey";
import CredentialsForm from "../../forms/user/CredentialsForm";
import AddressForm from "../../forms/user/AddressForm";
import SurveyForm from "../../forms/user/SurveyForm";
import Actions from "../../forms/user/Actions";
import SocialForm from "../../forms/user/SocialForm";
import If from "babel-plugin-jsx-control-statements"
import {OrcidRegistration} from "./OrcidRegistration";
import Social from "./Social";


const EditButton = ({allowEdit, name, showForm}) => {
    return (
        allowEdit &&
        <Row>
            <Col style={{marginTop: 20}}>
                <Button onClick={() => showForm(name)}>Edit</Button>
            </Col>
        </Row>
    )
};


const UserView = ({orcidRegistration = false, allowEdit, user, userRemove, userCredentialsUpdate, userAddressUpdate, userSurveyUpdate, userSocialUpdate, showActions}) => {
    const [form] = Form.useForm();

    const [activeform, setActiveForm] = useState({
        formName: null,
        action: null
    });

    const handleSubmitForm = () => {
        form
            .validateFields()
            .then(values => {
                const filteredValues = _.pickBy(values, v => v !== null && v !== undefined && v !== '');

                activeform.action(filteredValues, user.id)
                    .then((response) => message.info("Participant information has been updated."))
                    .catch((error) => message.error("Participant information has NOT been updated") && console.error(error));
                hideForms();
            })
            .catch(info => {

            });

    };

    useEffect(() => {
        if(activeform.formName !== null) {
            form.resetFields();
            form.setFieldsValue(user);
        }
    }, [activeform, form, user])

    const showForm = (formName) => {
        const forms = {
            credentials: userCredentialsUpdate,
            address: userAddressUpdate,
            survey: userSurveyUpdate,
            social: userSocialUpdate,
        };

        setActiveForm({
            formName: formName,
            action: forms[formName]
        })
    };


    const hideForms = () => {
        setActiveForm({
            formName: null,
            action: null
        })
    };


        return (
            <div>

                <Row gutter={50}>
                    <Col xs={24} lg={12}>
                        <Details user={user}/>
                        <EditButton allowEdit={allowEdit} showForm={() => showForm('credentials')}/>

                    </Col>
                    <Col xs={24} lg={12}>
                        <Invoice user={user}/>
                        <EditButton allowEdit={allowEdit} showForm={() => showForm('address')}/>
                    </Col>
                </Row>
                <Row gutter={50}>
                    <Col xs={24} lg={12}>
                        <Survey user={user}/>
                        <EditButton allowEdit={allowEdit} showForm={() => showForm('survey')}/>
                    </Col>

                    <Col xs={24} lg={12}>
                        <Social user={user}/>
                        <EditButton allowEdit={allowEdit} showForm={() => showForm('social')}/>
                    </Col>

                </Row>
                <Row gutter={50}>
                    <If condition={showActions}>
                        <Col xs={24} lg={12}>
                            <Actions userRemove={userRemove} user={user}/>
                        </Col>
                    </If>
                </Row>


                <Modal
                    forceRender
                    title="Participant details"
                    visible={activeform.formName === 'credentials'}
                    onOk={handleSubmitForm}
                    onCancel={() => hideForms()}
                    destroyOnClose={true}
                    width={720}
                >
                    <CredentialsForm
                        user={user}
                        colOffset={0}
                        form={form}
                    />
                </Modal>

                <Modal
                    forceRender
                    title="Mailing address"
                    visible={activeform.formName === 'address'}
                    onOk={handleSubmitForm}
                    onCancel={() => hideForms()}
                    destroyOnClose={true}
                >
                    <AddressForm
                        user={user}
                        colOffset={0}
                        form={form}
                    />
                </Modal>

                <Modal
                    forceRender
                    title="Survey"
                    visible={activeform.formName === 'survey'}
                    onOk={handleSubmitForm}
                    onCancel={() => hideForms()}
                    destroyOnClose={true}
                >
                    <SurveyForm
                        user={user}
                        colOffset={0}
                        form={form}
                    />
                </Modal>

                <Modal
                    forceRender
                    title="Social"
                    visible={activeform.formName === 'social'}
                    onOk={handleSubmitForm}
                    onCancel={() => hideForms()}
                    destroyOnClose={true}
                >
                    <SocialForm
                        colOffset={0}
                        form={form}
                    />
                </Modal>

                <If condition={orcidRegistration}>
                    <OrcidRegistration user={user}/>
                </If>
            </div>
        );
    };


UserView.propTypes = {
    user: PropTypes.object,
    allowEdit: PropTypes.bool,
};

export default UserView
