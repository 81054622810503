import React, {useEffect} from 'react';
import BasicLayout from "../../views/MainLayout";
import AbstractView from "../../fragments/AbstractView";
import { getAbstractById} from "../../redux-actions/author";
import CommentsView from "../../fragments/CommentsView";
import _ from "lodash-es"
import {Col, Row} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";

const AbstractPage = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const abstract = useSelector((state) => state.author.abstract);
    const sessions = useSelector((state) => state.settings.sessions);

    useEffect(() => {
        dispatch(getAbstractById(id))
    }, [id, dispatch])


        return (
            <BasicLayout>
                <Row gutter={[10, 10]}>
                    <Col xs={24}>
                        <AbstractView
                            abstract={abstract}
                            sessionTitle={_.get(sessions.find(s => s.acronym === abstract.session), 'title', '')}
                        />
                    </Col>
                    <Col xs={24}>
                        <CommentsView data={abstract.reviews} title='Reviews'/>
                    </Col>
                </Row>
            </BasicLayout>
        );
}

export default AbstractPage

