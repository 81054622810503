import React, {PureComponent} from 'react';
import {Layout} from 'antd';
import styles from './NoMenuLayout.module.scss'
import PropTypes from 'prop-types'
import {withRouter} from "react-router-dom";
import config from "../config"

const {Content} = Layout;
const {title, subTitle, conferenceSiteUrl} = config
const {backgroundImage} = config

class BasicLayout extends PureComponent {
    render() {
        return (
            // <Layout className={styles.layout}>
                <Layout style={{backgroundImage: `url('${backgroundImage}')`}} className={styles.layout}>
                <Content >
                    <div className={styles.logo} onClick={() => this.props.history.push('/')} unselectable="on" >
                        {title}
                    </div>
                    <div className={[styles.logo, styles.subheader].join(' ')}>
                        {subTitle}
                    </div>

                    <div>
                            {this.props.children}
                    </div>


                    <div className={styles.returnToConferenceSite}>
                        <span><a href={conferenceSiteUrl}>Back to conference website</a></span>
                    </div>

                </Content>

            </Layout>
        );
    }
}

BasicLayout.propTypes = {
    width: PropTypes.any,
    minWidth: PropTypes.any
};

BasicLayout.defaultProps = {
    width: '50%',
    minWidth: 720
};

export default withRouter(BasicLayout);
