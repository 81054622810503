import React from "react";
import {ROLE_ADMIN, ROLE_AUTHOR, ROLE_EDITOR, ROLE_REVIEWER} from "../constants/strings";
import {reactLazyPreload} from "./reactLazyPreload";
// Always loaded
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import Registration from "../pages/Registration";
import FeePage from "../pages/author/FeePage";
import DocumentsPage from "../pages/author/Documents";
import ProfilePage from "../pages/author/ProfilePage";
import AbstractsList from "../pages/author/AbstractsList";
import AbstractSubmission from "../pages/author/AbstractSubmission";
import AbstractPage from "../pages/author/AbstractPage";
import PapersListPage from "../pages/author/PapersList";
import PaperSubmissionPage from "../pages/author/PaperSubmission";
import PaperPage from "../pages/author/Paper";

// Reviewers component lazy load
const AbstractsReviewsList = reactLazyPreload(() => import("../pages/reviewer/AbstractsReviewsList"))
const AbstractReview = reactLazyPreload(() => import("../pages/reviewer/AbstractReview"))
const PapersReviewsList = reactLazyPreload(() => import("../pages/reviewer/PapersReviewsList"))
const PaperReview = reactLazyPreload(() => import("../pages/reviewer/PaperReview"))

// Admin components lazy load
const AdminUsers = reactLazyPreload(() => import("../pages/admin/AdminUsersList"))
const AdminDashboard = reactLazyPreload(() => import("../pages/admin/AdminDashboard"))
const AdminMailing = reactLazyPreload(() => import("../pages/admin/AdminMailing"))
const AdminImpersonateUser = reactLazyPreload(() => import("../pages/admin/AdminImpersonateUser"))
const AdminUserView = reactLazyPreload(() => import("../pages/admin/AdminUser"))
const AdminSettings = reactLazyPreload(() => import("../pages/admin/AdminSettings"))
const AdminPaperEditPage = reactLazyPreload(() => import("../pages/admin/AdminPaperEdit"))
const AdminPaperPage = reactLazyPreload(() => import("../pages/admin/AdminPaper"))
const AdminPapersListPage = reactLazyPreload(() => import("../pages/admin/AdminPapersList"))
const AdminAbstractsView = reactLazyPreload(() => import("../pages/admin/AdminAbstract"))
const AdminAbstractsList = reactLazyPreload(() => import("../pages/admin/AdminAbstractsList"))

const authorAccess = [ROLE_AUTHOR, ROLE_REVIEWER, ROLE_EDITOR, ROLE_ADMIN];
const reviewerAccess = [ROLE_REVIEWER, ROLE_EDITOR, ROLE_ADMIN];
const editorAccess = [ROLE_EDITOR, ROLE_ADMIN];
const adminAccess = [ROLE_ADMIN];

export const routing = [
    {
        path: '/', component: Login
    },
    {
        path: '/forgot-password', component: ForgotPassword
    },
    {
        path: '/reset-password/:token', component: ResetPassword
    },
    {
        path: '/register', component: Registration
    },
    {
        path: '/fee', component: FeePage, roles: authorAccess
    },
    {
        path: '/documents', component: DocumentsPage, roles: authorAccess
    },
    {
        path: '/profile', component: ProfilePage, roles: authorAccess
    },
    {
        path: '/abstracts', component: AbstractsList, roles: authorAccess
    },
    {
        path: '/abstracts/add', component: () => <AbstractSubmission type="submit"/>, roles: authorAccess
    },
    {
        path: '/abstracts/:id', component: AbstractPage, roles: authorAccess
    },
    {
        path: '/abstracts/:id/edit', component: () => <AbstractSubmission type="edit"/>, roles: authorAccess
    },
    {
        path: '/abstracts/:id/resubmit', component: () => <AbstractSubmission type="resubmit"/>, roles: authorAccess
    },
    {
        path: '/papers', component: PapersListPage, roles: authorAccess
    },
    {
        path: '/papers/add', component: () => <PaperSubmissionPage type="submit"/>, roles: authorAccess
    },
    {
        path: '/papers/:id', component: PaperPage, roles: authorAccess
    },
    {
        path: '/papers/:id/edit', component: () => <PaperSubmissionPage type="edit"/>, roles: authorAccess
    },
    {
        path: '/papers/:id/resubmit', component: () => <PaperSubmissionPage type="resubmit"/>, roles: authorAccess
    },
    {
        path: '/reviews/abstracts/', component: AbstractsReviewsList, roles: reviewerAccess
    },
    {
        path: '/reviews/abstracts/:id', component: AbstractReview, roles: reviewerAccess
    },
    {
        // For compatibility with old URLS. Needs to be removed in the future
        path: '/papers-reviews', component: PapersReviewsList, roles: reviewerAccess
    },
    {
        path: '/reviews/papers', component: PapersReviewsList, roles: reviewerAccess
    },
    {
        // For compatibility with old URLS. Needs to be removed in the future
        path: '/papers-reviews/:id', component: PaperReview, roles: reviewerAccess
    },
    {
        path: '/reviews/papers/:id', component: PaperReview, roles: reviewerAccess
    },
    {
        path: '/editor/abstracts', component: AdminAbstractsList, roles: adminAccess
    },
    {
        path: '/editor/abstracts/:id', component: AdminAbstractsView, roles: adminAccess
    },
    {
        path: '/editor/users', component: AdminUsers, roles: adminAccess
    },
    {
        path: '/editor/dashboard', component: AdminDashboard, roles: adminAccess
    },
    {
        path: '/editor/mailing', component: AdminMailing, roles: adminAccess
    },
    {
        path: '/editor/users/:id/impersonate', component: AdminImpersonateUser
    },
    {
        path: '/editor/users/:id/:tab?', component: AdminUserView, roles: adminAccess
    },
    {
        path: '/editor/settings', component: AdminSettings, roles: adminAccess
    },
    {
        path: '/editor/papers/:id/edit', component: AdminPaperEditPage, roles: editorAccess
    },
    {
        path: '/editor/papers/:id', component: AdminPaperPage, roles: editorAccess
    },
    {
        path: '/editor/papers', component: AdminPapersListPage, roles: editorAccess
    },
]

const findComponentsForRoles = (role) => {
    return routing.filter(route =>
        route.roles && route.roles.includes(role)
    ).map(route => route.component);
};

export const preloadComponentsFor = (role) => {
    if(!role || role === 'author') return;
    const components = findComponentsForRoles(role);
    for(const component of components) {
        if (component && component.preload) {
            component.preload();
        }
    }

};
