import React from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Row, Spin, Tag} from "antd";
import _ from "lodash-es";
import {Choose, Otherwise, When} from "babel-plugin-jsx-control-statements"
import styles from './FeeView.module.scss'
import useMediaQuery from 'use-media-query-hook';
import config from '../config'

const {feeText} = config

function FeeView ({user}) {
        let offset = useMediaQuery('(max-width: 991px)') ? 0 : 1
        return (
            <div>

                <Card title="Fee">
                    <Spin spinning={_.isEmpty(user.id)}>
                        <Row>
                            <Col lg={5} xs={24}>
                                <Card
                                    className={styles.feeCard}
                                    title="Fee status"
                                >
                                    <Row>
                                        <Col xs={24}>
                                            <Choose>
                                                <When condition={_.isUndefined(user.paid)}>
                                                    <Tag className={styles.feeTag}/>
                                                </When>
                                                <When condition={user.paid}>
                                                    <Tag className={styles.feeTag} color="blue">PAID</Tag>
                                                </When>
                                                <Otherwise>
                                                    <Tag className={styles.feeTag} color="red">NOT PAID</Tag>
                                                </Otherwise>
                                            </Choose>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} lg={18} offset={offset}>
                                <Card
                                    title="Information"
                                >
                                    <div>{feeText}</div>
                                </Card>
                            </Col>
                        </Row>
                    </Spin>
                </Card>
            </div>
        );
}

FeeView.propTypes = {
    user: PropTypes.object
};

export default FeeView;
