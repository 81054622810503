import React, {useCallback, useEffect, useState} from 'react';
import BasicLayout from "../../views/MainLayout";
import PaperSubmissionForm from "../../forms/paper/PaperSubmission";
import {
    clearPaper,
    getPaperById,
    resubmitPaper,
    searchKeywords,
    submitPaper,
    updatePaper
} from "../../redux-actions/author";
import {useHistory, useParams} from "react-router-dom";
import {Form, message} from "antd";
import * as Sentry from '@sentry/browser';
import {useDispatch, useSelector} from "react-redux";
import config from "../../config";

function showErrorMsg(exception) {
    console.log(exception.error)
    const msg = exception.error.response?.data?.error;
    console.log(msg)
    if(msg)
        message.error(msg);
    else
        message.error("The paper could not be sent due to errors.");

    Sentry.withScope((scope) => {
        scope.setExtras(exception.error.response);
        Sentry.captureException("Paper upload error");
    });
}

const PaperSubmissionPage = ({type}) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const {id} = useParams();

    const keywords = useSelector((state) => state.author.keywords)
    const user = useSelector((state) => state.auth.user)
    const paper = useSelector((state) => state.author.paper)

    // const getPaper =  getPaperById,
    //     updatePaper: updatePaper,
    //     submitPaper: submitPaper,
    //     clearPaper: clearPaper,
    //     resubmitPaper: resubmitPaper,

    // useEffect(() => {
    //     return () => {
    //         if (history.action === "POP")
    //             history.replace(history.location.pathname);
    //     }
    // }, [history]);



    useEffect(() => {
        if (id) dispatch(getPaperById(id))
    }, [id, dispatch]);     // only initial render!


    // Set forms data for resubmit
    useEffect(() => {
        if(paper.authors && paper.authors.length > 0) {
            form.setFieldsValue({
                ...paper,
                paperFile: type === 'submit' ? [] : [{
                    uid: paper.paperFile,
                    name: `Paper_${paper.authors[0].lastName}_${paper.authors[0].firstName}`,
                    url: `${config.apiUrl}/papers/${paper.id}/paper`,
                    status: 'done'
                }],
                copyrightFile: type === 'submit' ? [] : [{
                    uid: paper.copyrightFile,
                    name: `Copyright`,
                    url: `${config.apiUrl}/papers/${paper.id}/copyright`,
                    status: 'done'
                }]
            });
        }
    }, [form, paper, type]);


    // Set initial author
    useEffect(() => {
        if(type === 'submit') {
            const {firstName, lastName, email, affiliation} = user
            form.resetFields();
            form.setFieldsValue({
                authors: [
                    {
                        firstName, lastName, email, affiliation
                    }
                ]
            })
        }
    }, [type, form, user])

    useEffect(() => {
        return () => {
            dispatch(clearPaper());
        }
    }, [dispatch]);


    const handleKeywordsSearch = useCallback((word) => dispatch(searchKeywords(word)), [
        dispatch,
    ]);

    const handleSubmit = (values) => {
            const {paperFile, copyrightFile, ...meta} = values;
            setSubmitEnabled(false);

            if(type === 'submit') {
                dispatch(submitPaper(paperFile[0].originFileObj, copyrightFile?.[0].originFileObj, meta)).then((res => {
                    history.push('/papers');
                })).catch(exception => {
                    showErrorMsg(exception)
                    setSubmitEnabled(true);
                });
            } else if(type === 'edit') {
                dispatch(updatePaper(paperFile[0].originFileObj, copyrightFile?.[0].originFileObj, meta, paper.id)).then((res => {
                    history.goBack();
                })).catch(exception => {
                    showErrorMsg(exception)
                    setSubmitEnabled(true);
                });
            } else if(type === 'resubmit') {
                dispatch(resubmitPaper(paperFile[0].originFileObj, copyrightFile?.[0].originFileObj, meta, paper.id)).then((res => {
                    history.push('/papers');
                })).catch(exception => {
                    showErrorMsg(exception)
                    setSubmitEnabled(true);
                });
            }
    };

    return (
        <BasicLayout>
            <PaperSubmissionForm
                form={form}
                type={type}
                paper={paper}
                onSubmit={handleSubmit}
                submitEnabled={submitEnabled}
                keywords={Array.from(new Set(keywords))}
                onKeywordsSearch={handleKeywordsSearch}
            />
        </BasicLayout>
    );
};



export default PaperSubmissionPage
