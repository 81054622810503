import * as React from "react";

const OrcidIcon = (props) => {
  return (
      <span className="anticon">
    <svg
        fill="currentColor"
        aria-hidden="true"
        className="orcid_svg__icon"
        viewBox="96 96 830 830"
        width="1.1em"
        height="1.1em"
        {...props}
    >
      <defs>
        <style />
      </defs>
      <path d="M512 96C282.624 96 96 282.624 96 512s186.624 416 416 416 416-186.624 416-416S741.376 96 512 96zm0 64c194.784 0 352 157.216 352 352S706.784 864 512 864 160 706.784 160 512s157.216-352 352-352zm-160 96a32 32 0 000 64 32 32 0 000-64zm-32 96v352h64V352h-64zm128 0v352h144c96.832 0 176-79.168 176-176s-79.168-176-176-176H448zm64 64h80c62.208 0 112 49.792 112 112s-49.792 112-112 112h-80V416z" />
    </svg>
      </span>
  );
}

export default OrcidIcon;

