import * as Sentry from '@sentry/browser';
import {
    API_EDITOR_IMPERSONATE_USER,
    API_GEO_GET_COUNTRY,
    API_GET_USERINFO,
    API_LOGIN_USER,
    API_UPDATE_USER,
    API_UPDATE_USER_ADDRESS,
    API_UPDATE_USER_EVENTS, API_UPDATE_USER_SOCIAL,
    API_UPDATE_USER_SURVEY,
    EDIT_USER_FORM,
    LOGOUT,
    RETRIEVE_AUTH_FROM_STORAGE,
    TOKEN_LOGIN
} from "../constants/action-types";
import {setAuthHeaders} from "../services/axios";

const initialState = {
    token: '',                      // For now token is stored in global state for the AuthRouter checks
    user: {
        role: 'author',             // default role, needs to be provided
        canSubmitPaper: false       // do not allow to submit paper by default
    },
    country: ''
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${API_LOGIN_USER}_SUCCESS`:
            const auth = {
                token: action.payload.data.token,
                user: action.payload.data.user
            }
            localStorage.setItem('token', auth.token);
            setAuthHeaders(auth.token);
            Sentry.setUser({
                email: auth.user.email,
                id: auth.user.id,
                username: `${auth.user.firstName} ${auth.user.lastName}`
            });
            return {...state, ...auth};
        case TOKEN_LOGIN: {
            const token = action.payload.token
            const auth = {
                token
            }
            localStorage.setItem('token', token);
            setAuthHeaders(token);
            return {...state, ...auth};
        }
        case RETRIEVE_AUTH_FROM_STORAGE:
            const token = localStorage.getItem('token');
            setAuthHeaders(token);

            if (token) {
                return {...state, token};
            } else {
                return {...state};
            }
        case `${API_GET_USERINFO}_SUCCESS`:
        case `${API_UPDATE_USER}_SUCCESS`:
        case `${API_UPDATE_USER_ADDRESS}_SUCCESS`:
        case `${API_UPDATE_USER_EVENTS}_SUCCESS`:
        case `${API_UPDATE_USER_SURVEY}_SUCCESS`:
        case `${API_UPDATE_USER_SOCIAL}_SUCCESS`:
            return {
                ...state, user: action.payload.data
            };
        case LOGOUT:
            localStorage.clear()
            return {...initialState};
        case EDIT_USER_FORM:
            return {...state, userForm: {...state.userForm, ...action.changedFields}}
        case `${API_GEO_GET_COUNTRY}_SUCCESS`:
            return {...state, country: action.payload.data.country};
        case `${API_EDITOR_IMPERSONATE_USER}_SUCCESS`:
            setAuthHeaders(action.payload.data.token);
            return {...state, token: action.payload.data.token, user: action.payload.data.user};
        default:
            return state;
    }
}

export default userReducer;
