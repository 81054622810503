import React from 'react'
import {Col, Row} from "antd";
import config from "../config";
const {title, subTitle} = config

export const AppFooter = () => {
    return (
        <Row>
            <Col md={4} xs={24}><span style={{color: '#cdcdcd'}}>{<code>v{global.appVersion}</code>}</span></Col>
            <Col md={16} xs={24} style={{textAlign: 'center'}}>{title} - {subTitle}</Col>
            <Col md={4} xs={24} style={{textAlign: 'right'}}>Developed by <a
                href="mailto:kbzowski@agh.edu.pl">Krzysztof Bzowski</a></Col>
        </Row>
    )
}
