import {Button, Popconfirm, Tooltip} from "antd";
import React from "react";
import {DeleteOutlined} from "@ant-design/icons";

const DeleteButton = ({action}) =>
    <Tooltip placement="bottom" title="Remove submitted abstract">
        <Popconfirm
            title="Are you sure to delete this abstract?"
            onConfirm={action}
            okText="Yes"
            cancelText="No">
            <Button style={{margin: 5}} type={'danger'} shape="circle" icon={<DeleteOutlined/>}/>
        </Popconfirm>
    </Tooltip>

export default DeleteButton;
