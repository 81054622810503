import React from 'react';
import {Card, Col, Row} from "antd";
import _ from 'lodash-es'
import styles from './Abstract.module.scss'
import {withRouter} from "react-router-dom";
import config from "../config";
import If from "babel-plugin-jsx-control-statements"

import DownloadButton from "../components/DownloadButton";
import {StatusBadge} from "../components/StatusBadge";
import {AuhtorsList} from "./AuthorsList";

const columnSizes = {
    header: {
        xs: 24,
        md: 6,
        xl: 2
    },
    data: {
        xs: 24,
        md: 18,
        xl: 22
    }
};

const spacing = [0, 2];

const PaperProperty = ({label, children}) => {
    return (
        <Row gutter={spacing}>
            <Col className={styles.header} {...columnSizes.header}>
                <div>{label}:</div>
            </Col>
            <Col {...columnSizes.data}>
                <div>{children}</div>
            </Col>
        </Row>
    )
}

const PaperView = ({paper, extraButtons}) => {
    let keywords = '';
    if (paper.id) {
        keywords = _.get(paper, 'keywords', []).join(', ');
    }

    return (
        <div>
            <Card
                title="Paper"
                extra={extraButtons}
            >
                <If condition={paper?.intId}>
                    <PaperProperty label='No'>
                        <div>{paper?.intId}</div>
                    </PaperProperty>
                </If>
                <PaperProperty label='Title'>
                    <div>{paper?.title}</div>
                </PaperProperty>

                <PaperProperty label='Authors'>
                    <AuhtorsList authors={paper.authors} submitter={paper.owner} />
                </PaperProperty>

                <PaperProperty label='Keywords'>
                    <div>{keywords}</div>
                </PaperProperty>

                <PaperProperty label='Paper'>
                    <DownloadButton url={`/papers/${paper?.id}/paper`} label='Download paper'/>
                </PaperProperty>

                <If condition={paper.paperFileDate}>
                    <PaperProperty label='Last update'>
                        <div>{paper.paperFileDate.substring(0,10)}</div>
                    </PaperProperty>
                </If>

                <If condition={config.copyrightsForm}>
                    <PaperProperty label='Copyrights form'>
                        <DownloadButton url={`/papers/${paper?.id}/copyright`} label='Download copyrights form'/>
                    </PaperProperty>
                </If>

                    <PaperProperty label='Status'>
                        <StatusBadge status={paper?.status}/>
                    </PaperProperty>

            </Card>

        </div>
    );
}


export default withRouter(PaperView)
