import React from 'react';
import {Layout} from 'antd';
import AppMenu from "./AppMenu";
import styles from "./MainLayout.module.scss"
import config from "../config"
import {SolutionOutlined} from "@ant-design/icons";
import {AppFooter} from "./AppFooter";
import {useSelector} from "react-redux";
import {UserAvatar} from "../components/UserAvatar";
import {useLayoutDebug} from "../hooks/useLayoutDebug";

const {title} = config
const {Header, Sider, Content, Footer} = Layout;



const MainLayout = ({children}) => {
    const user = useSelector(state => state.auth.user);

    useLayoutDebug();

    return (
        <Layout className={styles.mainLayout}>
            <Header className={styles.header}>
                <div className={styles.logo}>
                    <SolutionOutlined className={styles.confIcon}/> {title}
                </div>

                <div className={styles.avatarWrapper}>
                        <UserAvatar firstName={user.firstName} lastName={user.lastName}/>
                </div>
            </Header>
            <Content className={styles.uiWrapper}>
                <Layout>
                    <Sider width={200}>
                        <AppMenu/>
                    </Sider>
                    <Content className={styles.appContent}>
                        {children}
                    </Content>
                </Layout>
            </Content>

            <Footer className={styles.footer}>
                <AppFooter/>
            </Footer>
        </Layout>
    );
}


export default MainLayout;

