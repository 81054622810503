import {applyMiddleware, combineReducers, createStore} from 'redux'
import createAxiosMiddleware from 'redux-axios-middleware';
import axiosClient from '../services/axios'
import reduxThunk from 'redux-thunk'
import {composeWithDevTools} from "redux-devtools-extension";
import userReducer from "../redux-reducers/auth";
import editorReducer from "../redux-reducers/editor";
import reviewerReducer from "../redux-reducers/reviewer";
import authorReducer from "../redux-reducers/author";
import settingsReducer from "../redux-reducers/settings";
import history from "../services/history"

export default function configureStore() {
    let reducers = combineReducers({
        auth: userReducer,
        author: authorReducer,
        editor: editorReducer,
        reviewer: reviewerReducer,
        settings: settingsReducer,
    })

    // Axios
    const axiosInterceptors = {
        response: [
            {
                error: function (store, error) {
                    const location = history.location;

                    // Skip for password related routings
                    if(location.pathname.includes('reset-password') ||
                        location.pathname.includes('registration') ||
                        location.pathname.includes('forgot-password')){
                        return Promise.reject(error)
                    }

                    let status = error.response.status
                    if(status === 401)
                        history.push('/')
                    return Promise.reject(error)
                }
            }
        ]
    }


    const axiosMiddleware = createAxiosMiddleware(axiosClient, {
        returnRejectedPromiseOnError: true,
        interceptors: axiosInterceptors
    });

    const develMiddlewares = [require('redux-immutable-state-invariant').default(), reduxThunk, axiosMiddleware]
    const prodMiddlewares = [reduxThunk, axiosMiddleware]

    const middlewares = process.env.NODE_ENV !== 'production' ? develMiddlewares : prodMiddlewares


    return createStore(reducers, composeWithDevTools(applyMiddleware(...middlewares)));
}
