import {checkMailAvailability} from "../redux-actions/author";

export default async (rule, value, dispatch) => {
    const response = await dispatch(checkMailAvailability(value))
    if (response.payload.data === true) {
        return Promise.resolve();
    }
    return Promise.reject('Email is already in use.');

}
