import React from 'react';
import PropTypes from 'prop-types';
import {Col, Divider, Row} from "antd";
import {columnSizes} from "./styles";
import If from "babel-plugin-jsx-control-statements";
import OrcidIcon from "../../components/OrcidIcon";

const Social = (props) => {
    const {user} = props

    return (
        <div>
            <Divider>Social Networks</Divider>
            <Row gutter={16}>
                <Col {...columnSizes.header}>
                    <div>ORCID identifier:</div>
                </Col>
                <Col {...columnSizes.data}>
                    <If condition={user.orcid}>
                        <OrcidIcon style={{marginRight: 5}}/>
                        <a href={`https://orcid.org/${user.orcid}`} target='_blank' rel="noopener noreferrer">{user.orcid}</a>
                    </If>
                </Col>
            </Row>
        </div>
    );
}

Social.propTypes = {
    user: PropTypes.object,
};
Social.defaultProps = {};

export default Social;
