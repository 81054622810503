import React from 'react';
import _ from 'lodash-es'
import {Alert, Button, Card, Col, Form, Input, Row} from 'antd';
import config from "../../config";
import capitalValidator from "../../validators/notAllowAllCapital";
import If from "babel-plugin-jsx-control-statements";
import {LoadingOutlined, PlusOutlined, RightOutlined} from "@ant-design/icons";
import {SubmissionUpload} from "../common/SubmissionUpload";
import authorSizes from "./Author.sizes"
import Author from "../common/Author";
import {moveDownAuthor, moveUpAuthor} from "../common/Author";
import {KeywordsSelect} from "../../components/KeywordsSelect";

const {paperTemplate, copyrightsForm} = config;
const FormItem = Form.Item;


const Comment = ({hint, title, fieldName, disabled}) => {
    return (
        <Row>
        <Col xs={24}>
            <FormItem
                label={title}
                name={[...fieldName, 'comment']}
            >
                    <Input.TextArea disabled={disabled} placeholder={hint} rows={3}/>
            </FormItem>
            <FormItem noStyle name={[...fieldName, 'disabled']} initialValue={disabled}>
                    <Input type='hidden'/>
            </FormItem>
        </Col>
    </Row>)
}

const PaperSubmission = ({form, paper, keywords, onKeywordsSearch, type, onSubmit, submitEnabled}) => {
    const InfoMessage = () => {
        return (
            <div>
                <ul>
                    <li>An accepted abstract is required to submit a full paper.</li>
                    <li>Please use <a
                        href={paperTemplate}>this template</a> to prepare your paper.
                    </li>
                    <If condition={config.copyrightsForm}>
                        <li><a
                            href={copyrightsForm}>Copyrights form</a> needs to be signed by all authors.
                        </li>
                    </If>
                </ul>
            </div>
        )
    };


    const onFinish = (values) => {
           onSubmit(values)
    }

    const getTitleCaption = () => {
        switch(type) {
            case 'submit': return "Submit full paper";
            case 'edit':
            case 'resubmit': return 'Edit submission';
            default: return '';
        }
    };

    const getSubmitButtonCaption = () => {
        switch(type) {
            case 'submit': return 'Submit';
            case 'edit': return 'Save';
            case 'resubmit': return 'Submit';
            default: return 'Save';
        }
    };


    return (
        <Card title={getTitleCaption()} style={{marginBottom: 20}}>
            {type === 'submit' && <Row>
                <Col xs={24}>
                    <Alert
                      style={{marginBottom: 20}}
                      showIcon
                      closable
                      message="Paper submission rules & guidelines"
                      description={<InfoMessage/>}
                      type="info"
                    />

                </Col>
            </Row>
            }

            <Form form={form} onFinish={onFinish} layout='vertical'>
                <p className="ant-form-item-required">Authors:</p>
                <Form.List name="authors">
                    {(fields, {add, remove}) => {
                        return (
                            <div>
                                    {
                                        fields.map((field, index) => (

                                            <Author
                                                sizes={authorSizes}
                                                showSpeakerSelection={false}
                                                field={field}
                                                key={field.key}
                                                index={index}
                                                count={fields.length}
                                                onRemove={remove}
                                                onMoveDown={() => moveDownAuthor(form, index)}
                                                onMoveUp={() => moveUpAuthor(form, index)}
                                            />))

                                    }
                                <Row gutter={25}>
                                    <Col xs={3}>
                                        <FormItem>
                                            <Button type="primary" onClick={() => add()}>
                                                <PlusOutlined/> Add author
                                            </Button>
                                        </FormItem>
                                    </Col>
                                </Row>

                            </div>
                        )
                    }}


                </Form.List>


                <Row>
                    <Col xs={24}>
                        <FormItem
                            name='title'
                            label="Title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Title is required'
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        return capitalValidator(rule, value)
                                    }
                                })

                            ]}>
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={25}>
                    <Col lg={24} xs={24}>
                        <FormItem
                            label="Keywords"
                            name="keywords"
                            rules={[{
                                required: true, message: 'Keywords are required',
                            }]}
                        >
                            <KeywordsSelect onSearch={onKeywordsSearch} keywords={keywords}/>
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                        <Col xs={24}>
                            <SubmissionUpload
                                label='Full Paper'
                                name='paperFile'
                                accept='.doc, .docx, .pdf, .zip, .gz, .docm'
                            />
                        </Col>
                    <If condition={config.copyrightsForm}>
                        <Col xs={24}>
                            <SubmissionUpload
                                label='Copyrights form'
                                name='copyrightFile'
                                accept='.pdf'
                            />
                        </Col>
                    </If>
                </Row>

                <If condition={type === 'resubmit'}>
                    <Comment
                        form={form}
                        fieldName="editor-answer"
                        title={'Message for the editor'}
                        hint={`Message for the editor...`}
                    />
                    {paper?.reviews?.map((review, num) => <Comment
                        key={review.id}
                        form={form}
                        title={`Reply to the the reviewer ${num+1}`}
                        fieldName={['reviewer-answer', num]}
                        hint={`Reply to the the reviewer ${num + 1}...`}
                        disabled={_.last(review.comments)?.status === 'ACCEPTED'}
                    />)}
                </If>
                <FormItem >
                    <Button type="primary" disabled={!submitEnabled} htmlType="submit" icon={submitEnabled ? <RightOutlined/> : <LoadingOutlined/>}>{getSubmitButtonCaption()}</Button>
                </FormItem>
            </Form>
        </Card>
    );
};

export default PaperSubmission;

