import React from 'react'
import styled from "styled-components";
import {Button, Col, Form, Radio} from "antd";

const FormItemInternal= styled(Form.Item)`
  margin-bottom: 0;
`

export const FormItem = React.forwardRef( (props, ref) => <FormItemInternal {...props}  />);


export const ButtonGroup = styled(Button.Group)`
  padding-top: 2px;
`

export const RightAlignCol = styled(Col)`
  text-align: right;
`

export const StyledRadioInternal = styled(Radio)`
  padding-top: 5px;
`
export const StyledRadio = React.forwardRef( (props, ref) => <StyledRadioInternal {...props}  />);
