import React from 'react';
import {Button, Card, Form, Input} from 'antd';
import {useHistory} from "react-router-dom";

const FormItem = Form.Item;


const formItemLayout = {
    labelCol: {
        sm: {span: 24},
    },
    wrapperCol: {
        sm: {span: 24},
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        sm: {span: 22},
    },
};

const ReviewResponse = ({action}) => {
    const [form] = Form.useForm();
    const history = useHistory();

    const handleSubmit = (values) => {
        action({
            comment: values.comment,
        }).then((response) => {
            history.push('/reviews')
        })
    }


    return (
        <div>
            <Card title="Response" style={{...this.props.style}}>
                <Form form={form} onFinish={handleSubmit} layout='vertical'>
                    <FormItem
                        {...formItemLayout}
                        label="Comments"
                        name='comment'
                        rules={[
                            {required: true, message: 'Please write a message!'},
                        ]}
                    >
                            <Input.TextArea rows={3}/>
                    </FormItem>
                    <FormItem {...formItemLayoutWithOutLabel}>
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </FormItem>

                </Form>
            </Card>
        </div>
    );
}


export default ReviewResponse

