import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";
import "dayjs/locale/en";
import registerServiceWorker from "./registerServiceWorker";
import AppLoader from "./AppLoader";

registerServiceWorker();

ReactDOM.render(<AppLoader />, document.getElementById("root"));
//registerServiceWorker();
