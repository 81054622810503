import {Grid} from "antd";
import {useDebounce} from "react-recipes";

export const useLayoutDebug = () => {
    const gridBP = Grid.useBreakpoint()
    const gridBPDebounced = useDebounce(gridBP, 500);

    if(process.env.DEBUG_LAYOUT) {
        if (Object.keys(gridBPDebounced).length)
            console.log(gridBPDebounced)
    }
}
