export default {
    sort: {
        xxl: 1,
        xl: 2,
        sm: 4
    },
    firstName: {
        xxl: 4,
        xl: 4,
        sm: 10
    },
    lastName: {
        xxl: 4,
        xl: 4,
        sm: 10
    },
    email: {
        xxl: {span: 5, offset: 0},
        xl: {span: 5, offset: 0},
        sm: {span: 10, offset: 4}
    },
    affiliation: {
        xxl: 9,
        xl: 8,
        sm: 10
    },
    remove: {
        xxl: 1,
        xl: 1,
        sm: 3
    }
}
