import React from 'react';
import {Col, Form, Input, Row} from "antd";
import PropTypes from 'prop-types'
import orcidValidator from "../../validators/orcid";

const SocialForm = ({form}) => {
    return (
        <Form form={form} layout="horizontal">
            <Row>
                <Col xs={24}>
                    <Form.Item
                        name='orcid'
                        label='ORCID identifier'
                        rules={[
                            ({getFieldValue}) => ({
                                validator(rule, value) {
                                    return orcidValidator(rule, value)
                                }
                            })
                        ]}
                    >
                        <Input data-cy='orcid'/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

SocialForm.propTypes = {
    user: PropTypes.object,
    colOffset: PropTypes.number,
};

SocialForm.defaultProps = {
    colOffset: 2
}


export default SocialForm;
