import React, {Component} from 'react';
import BasicLayout from "../../views/MainLayout";
import PaperView from "../../fragments/PaperView";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {clearPaper, deletePaper, getPaperById} from "../../redux-actions/author";
import If from "babel-plugin-jsx-control-statements"
import AuthorReviewActions from "../../forms/AuthorReviewActions";
import {conference} from "../../constants/strings";
import {Col, Row} from "antd";
import CommentsViewExt from "../../fragments/CommentsViewExt";


const spacing = [0, 10]

class PaperPage extends Component {
    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.getPaperById(id)
    }

    render() {
        const {paper} = this.props;
        const cardStyle = {marginTop: 20}

        const shouldShowActions = conference.authorCanResponse.includes(paper.status);
        return (
            <BasicLayout>
                <Row gutter={spacing}>
                    <Col xs={24}>
                        <PaperView
                            paper={paper}
                        />
                    </Col>
                </Row>

                <Row gutter={spacing}>
                    <Col xs={24}>
                        <CommentsViewExt
                            data={paper.editor}
                            title="Editor comments"
                            onCreateUrl={(id) => `/papers/editor-comments/${id}/file`}
                        />
                    </Col>
                </Row>

                {
                    paper.reviews?.map(review => (
                            <Row gutter={spacing} key={review.id}>
                                <Col xs={24}>
                                    <CommentsViewExt
                                        data={review.comments}
                                        title="Review"
                                        onCreateUrl={(id) => `/papers/reviewer-comments/${id}/file`}
                                    />
                                </Col>
                            </Row>
                        )
                    )
                }

                <If condition={shouldShowActions}>
                    <Row gutter={spacing}>
                        <Col xs={24}>
                            <AuthorReviewActions style={cardStyle}
                                                 action={() => this.props.history.push(`/papers/${paper.id}/resubmit`)}/>
                        </Col>
                    </Row>
                </If>

            </BasicLayout>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    user: state.auth.user,
    paper: state.author.paper,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getPaperById: getPaperById,
        deletePaper: deletePaper,
        clearPaper: clearPaper,
    },
    dispatch,
)


export default connect(mapStateToProps, mapDispatchToProps)(PaperPage)

