import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {List} from "antd";
import {NoPaddingCard} from "./Components/NoPaddingCard";
import DownloadButton from "../components/DownloadButton";
import styles from './Documents.module.scss'

class Documents extends Component {

    columns = [
        {
            title: 'Filename',
            dataIndex: 'name',
            render: (value, record, index) => (
                <DownloadButton label={value} url={record.url}/>
            ),
        }
    ]


    render() {
        const {user} = this.props

        let documents = [];
        if(user.documents) {
            documents = user.documents.map((doc, index) => ({
                id: `doc-${index}`,
                name: doc.includes('_') ? doc.split('_').slice(1).join() : doc,
                url: `/users/${user.id}/documents/${doc}`
            }))
        }

        return (
            <NoPaddingCard title="Documents">
                 <List dataSource={documents} rowKey='id'
                             renderItem={item => (
                                 <List.Item className={styles.document}>
                                     <DownloadButton label={item.name} url={item.url}/>
                                 </List.Item>
                             )}>

                 </List>
            </NoPaddingCard>
        );
    }
}

Documents.propTypes = {
    user: PropTypes.object
};

export default Documents;
