import {Button, Tooltip} from "antd";
import React from "react";
import {EditOutlined} from "@ant-design/icons";

const EditButton = ({disabled, action}) =>
    <Tooltip placement="bottom" title="Edit submitted abstract">
        <Button style={{margin: 5}}  type='primary' shape="circle" disabled={disabled} icon={<EditOutlined/>} onClick={action}/>
    </Tooltip>

export default EditButton;
