import cd from 'content-disposition'

export const browserDownload = (response) => {
    const disposition = cd.parse(response.headers['content-disposition']);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank'
    link.setAttribute('download', disposition.parameters.filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
}
