import React from 'react';
import dayjs from "dayjs";
import {StyledTable} from "../lists/Components/StyledTable";
import {NoPaddingCard} from "../lists/Components/NoPaddingCard";
import {StatusBadge} from "../components/StatusBadge";
import {If} from "babel-plugin-jsx-control-statements";
import {MultilineText} from "../components/MultilineText";
import DownloadButton from "../components/DownloadButton";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";


const CommentsViewExt = ({data, title = "", onCreateUrl = false, actionButtons = [], withPublishStatus = false, children}) => {

    const baseColumns = [
        {
            title: 'Date',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: 160,
            render: (text, record, i) => <span>{dayjs(text).format('YYYY-MM-DD HH:mm:ss')}</span>
        },
        {
            title: 'From',
            dataIndex: 'sourceName',
            width: 130,
            key: 'sourceName',
        },
        {
            title: 'Status change',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (text, record, i) => <StatusBadge status={text}/>
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            render: (text, record, i) => <MultilineText>{text}</MultilineText>
        },
        {
            title: 'File',
            dataIndex: 'file',
            width: 150,
            render: (file, record, i) => <If condition={file}><DownloadButton label="Attachment" url={onCreateUrl(record.id)}/></If>
        }
    ];


    let columns = baseColumns

    if (children) {
        columns = [...baseColumns, {
            title: 'Actions',
            key: 'action',
            width: 100,
            render: (_, record, i) => children(record)
        }]
    }

    if (withPublishStatus) {
        columns = [{
            title: 'Hidden',
            dataIndex: 'published',
            width: 50,
            render: (published, record, i) => published ? <EyeOutlined /> : <EyeInvisibleOutlined />
        }, ...columns]
    }


    return (
        <div>
            <NoPaddingCard title={title} extra={actionButtons}>
                <StyledTable size='small' rowKey="id" dataSource={data} columns={columns} pagination={false}/>
            </NoPaddingCard>
        </div>
    );
}

export default CommentsViewExt;
