import React from "react";
import {Button, Form, Upload} from "antd";
import {CloudUploadOutlined} from "@ant-design/icons";

export const SubmissionUpload = ({name, label, accept}) => {

    const uploaderProps = {
        multiple: false,
        accept,
        beforeUpload: file => {
          return false
        },
        onRemove: file => {

        },
        onDownload: (file) => {

        },
        onChange: (file) => {
        }
    };

    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        // Get last upload file only
        e.fileList = e.fileList.slice(-1);

        return e && e.fileList;
    };

    return (
        <Form.Item
            name={name}
            label={label}
            valuePropName='fileList'
            getValueFromEvent={normFile}
            rules={[{
                required: true,
                message: "File is required",
            }]}
        >
        <Upload {...uploaderProps} data-cy='file-upload'>
            <Button>
                <CloudUploadOutlined /> Click to Upload
            </Button>
        </Upload>
        </Form.Item>
    );
};
