const notAllowAllCapital = (rule, value) => {
    return !(value && value.length > 2 &&value === value.toUpperCase())
}

export default (rule, value) => {
    if(notAllowAllCapital(rule, value)){
        return Promise.resolve();
    }
    return Promise.reject('Please don\'t use only capital letters.');
}
