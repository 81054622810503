import React from 'react';
import PropTypes from 'prop-types';
import {Col, Divider, Row} from "antd";
import _ from "lodash-es";
import ColorTag from "../../components/ColorTag";

function Survey(props) {
    const {user} = props

    const columnSizes = {
        header: {
            xs: 21,
            xl: 18
        },
        data: {
            xs: 3,
            xl: 6
        }
    }

    return (
        <div>
            <Divider>Survey</Divider>
            {/*<Row gutter={16}>*/}
            {/*    <Col {...columnSizes.header}>*/}
            {/*        <div>Accommodation:</div>*/}
            {/*    </Col>*/}
            {/*    <Col {...columnSizes.data}>*/}
            {/*        <ColorTag data={_.get(user, 'accommodation', null)}/>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*<Row gutter={16}>*/}
            {/*    <Col {...columnSizes.header}>*/}
            {/*        <div>Pro-Forma invoice:</div>*/}
            {/*    </Col>*/}
            {/*    <Col {...columnSizes.data}>*/}
            {/*        <ColorTag data={_.get(user, 'proformaInvoice', null)}/>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Row gutter={16}>
                <Col {...columnSizes.header}>
                    <div>Acceptance letter:</div>
                </Col>
                <Col {...columnSizes.data}>
                    <ColorTag data={_.get(user, 'acceptanceLetter', null)}/>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col {...columnSizes.header}>
                    <div>Invitation letter:</div>
                </Col>
                <Col {...columnSizes.data}>
                    <ColorTag data={_.get(user, 'invitationLetter', null)}/>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col {...columnSizes.header}>
                    <div>Certificate of attendance:</div>
                </Col>
                <Col {...columnSizes.data}>
                    <ColorTag data={_.get(user, 'certificate', null)}/>
                </Col>
            </Row>
            {/*<Row gutter={16}>*/}
            {/*    <Col {...columnSizes.header}>*/}
            {/*        <div>Diet details:</div>*/}
            {/*    </Col>*/}
            {/*    <Col {...columnSizes.data}>*/}
            {/*        <div>{_.get(user, 'diet', '')}</div>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </div>
    );
}

Survey.propTypes = {
    user: PropTypes.object,

};
Survey.defaultProps = {};

export default Survey;
