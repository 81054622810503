import React from 'react'
import styles from "./AuthorsList.module.scss";
import {Button} from "antd";
import {MailOutlined} from "@ant-design/icons";
import SoundOutlined from "@ant-design/icons/lib/icons/SoundOutlined";
import If from "babel-plugin-jsx-control-statements"

export const AuhtorsList = ({authors = [], submitter = {}, speakerNumber = -1}) => {
    return (
        <ul className={styles.authorList}>
            {authors.map((author, idx) => {
                    const isSubmitter = submitter.id === author.refId;
                    const speakerIcon = idx === speakerNumber ? <SoundOutlined style={{marginRight: 5}}/> : null

                    return (<li key={author.id}>
                        {
                            <>
                                {speakerIcon}
                                <span>
                                        {author.firstName} {author.lastName}, {author.affiliation}
                                    <a href={`mailto:${author.email}`}><Button size="small" type="link"
                                                                               icon={<MailOutlined/>}/></a>

                                </span>
                                <If condition={isSubmitter}>
                                    <span>(submitter)</span>
                                </If>
                            </>
                        }
                    </li>)
                }
            )}</ul>
    )
}
