import React, {useCallback, useEffect} from 'react';
import BasicLayout from "../../views/MainLayout";
import AbstractSubmissionForm from "../../forms/abstract/AbstractSubmission";
import {
    getAbstractById,
    resubmitAbstract,
    searchKeywords,
    submitAbstract,
    updateAbstract
} from "../../redux-actions/author";
import {useHistory, useParams} from "react-router-dom";
import {Form, message} from "antd";
import {useDispatch, useSelector} from "react-redux";

const AbstractsPage = ({type}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    let history = useHistory();
    const {id} = useParams();

    const sessions = useSelector((state) => state.settings.sessions)
    const keywords = useSelector((state) => state.author.keywords)
    const user = useSelector((state) => state.auth.user)
    const abstract = useSelector((state) => state.author.abstract)

    // useEffect(() => {
    //     return () => {
    //         if (history.action === "POP")
    //             history.replace(history.location.pathname);
    //     }
    // }, [history])

    // Set initial author
    useEffect(() => {
        if(type === 'submit') {
            const {firstName, lastName, email, affiliation} = user
            form.resetFields();
            form.setFieldsValue({
                authors: [
                    {
                        firstName, lastName, email, affiliation
                    }
                ]
            })
        }
    }, [type, form, user])

    useEffect(() => {
        if(type === 'edit' || type === 'resubmit') {
            if (id) dispatch(getAbstractById(id))
        }
    }, [dispatch, id, type]);     // only initial render!



    const handleKeywordsSearch = useCallback((word) => dispatch(searchKeywords(word)), [
        dispatch,
    ]);


    const handleSubmit = (values) => {
        const {file, ...meta} = values;

        if (type === 'submit') {
            dispatch(
                submitAbstract(file[0].originFileObj, meta)
            ).then((res => {
                history.push('/abstracts');
            })).catch(err => message.error("The abstract could not be sent due to errors."));
        } else if (type === 'edit') {
            dispatch(
                updateAbstract(file[0].originFileObj, meta, abstract.id)
            ).then((res => {
                history.goBack();
            })).catch(err => message.error("The abstract could not be updated due to errors."));
        } else if (type === 'resubmit') {
            dispatch(
                resubmitAbstract(file[0].originFileObj, meta, abstract.id)
            ).then((res => {
                history.push('/abstracts');
            })).catch(err => message.error("The abstract could not be updated due to errors."));
        }
    }


    return (
        <BasicLayout>
            <AbstractSubmissionForm
                type={type}
                onSubmit={handleSubmit}
                keywords={Array.from(new Set(keywords))}
                form={form}
                onKeywordsSearch={handleKeywordsSearch}
                sessions={sessions}
                initialValues={abstract}
            />
        </BasicLayout>
    );
};



export default AbstractsPage
