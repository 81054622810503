import React from 'react'
import {Avatar, Dropdown, Menu} from "antd";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {logout} from "../redux-actions/auth";
import {EditOutlined, LogoutOutlined} from "@ant-design/icons";
import styles from './UserAvatar.module.scss'

const stringToHslColor = (str, s, l) => {
    if(!str) return 'hsl(0,0,0)'

    let hash = 0;
    str = str.toLowerCase();
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let h = hash % 360;
    return 'hsl('+h+', '+s+'%, '+l+'%)';
}

export const UserAvatar = ({firstName, lastName}) => {
    const dispatch = useDispatch()
    const history = useHistory();

    const handleLogout = () => {
        dispatch(logout())
        history.push('/')
    }

    const handleNavToProfile = () => {
        history.push('/profile')
    }

    const menu = (
        <Menu>
            <Menu.Item key="editUser" onClick={handleNavToProfile}><EditOutlined/> Edit profile
                information</Menu.Item>
            <Menu.Item key="logout" onClick={handleLogout}><LogoutOutlined/> Logout</Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['hover']}>
            <Avatar className={styles.avatar} style={{ backgroundColor: stringToHslColor(firstName, 50, 60)}}>
                {firstName?.charAt(0)}{lastName?.charAt(0)}
            </Avatar>
        </Dropdown>
    )
}
