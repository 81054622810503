import React from 'react';
import ForgotPasswordForm from "../forms/RequestNewPassword";
import NoMenuLayout from "../views/NoMenuLayout";
import styles from "../views/NoMenuLayout.module.scss";

function ForgotPassword(props) {
    return (
        <NoMenuLayout>
        <div className={`${styles.centerPanel} ${styles.panelForgot}`}>
            <div className={styles.panel}>
                <ForgotPasswordForm />
            </div>
        </div>
        </NoMenuLayout>
    );
}

ForgotPassword.propTypes = {};
ForgotPassword.defaultProps = {};

export default ForgotPassword;
