import React from 'react'
import {Button, Col, Input, Row, Tooltip} from "antd";
import capitalValidator from "../../validators/notAllowAllCapital";
import If from "babel-plugin-jsx-control-statements"
import {CaretDownOutlined, CaretUpOutlined, DeleteOutlined} from "@ant-design/icons";
import {ButtonGroup, FormItem, RightAlignCol, StyledRadio} from "../StyledFormsItems";


const Author = ({index, count, onRemove, onMoveDown, onMoveUp, field, sizes, showSpeakerSelection}) => {

    return (
        <Row gutter={[19, 5]}>
            <Col {...sizes.sort}>
                <ButtonGroup>
                    <Button size="small" icon={<CaretUpOutlined/>} disabled={index === 0} onClick={onMoveUp}/>
                    <Button size="small" icon={<CaretDownOutlined/>} disabled={index === (count - 1)} onClick={onMoveDown}/>
                </ButtonGroup>
            </Col>
            <Col {...sizes.firstName}>
                <FormItem
                    required={false}
                    name={[field.name, "firstName"]}
                    fieldKey={[field.fieldKey, "firstName"]}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[{
                        required: true,
                        whitespace: true,
                        message: "Please input author first name.",
                    },
                        ({getFieldValue}) => ({
                            validator(rule, value) {
                                return capitalValidator(rule, value)
                            }
                        })
                    ]}
                >
                    <Input placeholder="First name"/>
                </FormItem>
            </Col>
            <Col {...sizes.lastName}>
                <FormItem
                    required={false}
                    name={[field.name, "lastName"]}
                    fieldKey={[field.fieldKey, "lastName"]}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[{
                        required: true,
                        whitespace: true,
                        message: "Please input author's last name.",
                    },
                        ({getFieldValue}) => ({
                            validator(rule, value) {
                                return capitalValidator(rule, value)
                            }
                        })
                    ]}
                >
                    <Input placeholder="Last name"/>
                </FormItem>
            </Col>
            <Col {...sizes.email} >
                <FormItem
                    required={false}
                    name={[field.name, "email"]}
                    fieldKey={[field.fieldKey, "email"]}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[{
                        required: true,
                        whitespace: true,
                        type: 'email',
                        message: "Please input author's email",
                    },
                        ({getFieldValue}) => ({
                            validator(rule, value) {
                                return capitalValidator(rule, value)
                            }
                        })
                    ]}
                >
                    <Input placeholder="Email"/>
                </FormItem>
            </Col>
            <Col {...sizes.affiliation}>
                <FormItem
                    required={false}
                    name={[field.name, "affiliation"]}
                    fieldKey={[field.fieldKey, "affiliation"]}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[{
                        required: true,
                        whitespace: true,
                        message: "Please input author's affiliation.",
                    },
                    ]}
                >
                    <Input placeholder="Affiliation"/>
                </FormItem>
            </Col>

            <If condition={showSpeakerSelection}>
                <RightAlignCol {...sizes.speaker}>
                    <Tooltip placement="right" title="Speaker">
                            <StyledRadio value={index}/>
                    </Tooltip>
                </RightAlignCol>
            </If>

            <RightAlignCol {...sizes.remove}>
                <If condition={count > 1}>
                    <Tooltip placement="topLeft" title="Remove author">
                        <Button icon={<DeleteOutlined/>} shape="circle" onClick={() => onRemove(field.name)}/>
                    </Tooltip>
                </If>
            </RightAlignCol>
        </Row>
    )
}

export default Author;

export const moveDownAuthor = (form, index) => {
    let {authors} = form.getFieldsValue(['authors']);
    [authors[index], authors[index+1]] = [authors[index+1], authors[index]];
    form.setFieldsValue({authors});
};

export const moveUpAuthor = (form, index) => {
    let {authors} = form.getFieldsValue(['authors']);
    [authors[index], authors[index-1]] = [authors[index-1], authors[index]];
    form.setFieldsValue({authors});
};

